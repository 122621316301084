import _ from 'lodash';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/scss/main.scss';

import Logo  from './assets/images/logo_icon.svg';

function render() {
  const element = document.createElement('div');
  element.id = "root";
  element.innerHTML =_.join([header(), content(), footer()], ' ');

  return element;
}

function header(){
  return `<nav class="navbar">
  <span class="navbar-brand">
    <a href=${config.codemastersUrl} rel="noopener noreferrer"><img src=${Logo} class="navbar__logo d-inline-block align-top" alt="Codemasters" /> 
    Codemasters
  </a></span>
  </nav>`;
}

function getYearString() {
  var releaseYear = 2020;
  var now = new Date();
  var currentYear = now.getFullYear();
  return releaseYear === currentYear ? releaseYear : releaseYear + "-" + currentYear;
}

function content(){
  return `<main class="main">
    <div class="main__content">
      <div class="main__content--wrapper">    
      <img src=${Logo} class="main__content--logo" alt="Codemasters" />
          <p class="main__content--text">Unfortunately that site no longer exists</p>
          <p class="main__content--subtext">You can view our games on <a href=${config.codemastersUrl} target="_blank" rel="noopener noreferrer">Codemasters.com</a></p>
          <ul class="main__content--social">
            <li>
              <a href=${config.twitterUrl} target="_blank" rel="noopener noreferrer"><i class="fab fa-twitter"></i></a>
            </li>
            <li> 
              <a href=${config.facebookUrl} target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook"></i></a>
            </li>
            <li>
            <a href=${config.youtubeUrl} target="_blank" rel="noopener noreferrer"><i class="fab fa-youtube"></i></a>
            </li>
          </ul>
      </div>
    </div>
  </main>`;
}

function footer() {
  return `<footer>
    <div class="footer__content">  
    <img src=${Logo} alt="Codemasters" class="footer__logo" />
      <p>&copy; ${getYearString()} The Codemasters Software Company Limited (“Codemasters”). All rights reserved. “Codemasters”® and the Codemasters logo&reg; are registered trademarks owned by Codemasters. All Rights Reserved. All other trademarks or copyrights are the property of their respective owners and are used under license. Developed and published by Codemasters.</p>
      <nav class="footer__links">
        <ul>
          <li><a href=${config.contactUsUrl} rel="noopener nofollow">CONTACT US</a></li>
          <li><a href=${config.termsUrl} rel="noopener nofollow">TERMS &amp; CONDITIONS</a></li>
          <li><a href=${config.privacyUrl} rel="noopener nofollow">PRIVACY POLICY</a></li>
          <li><a href=${config.creatorsUrl} rel="noopener nofollow">CONTENT CREATORS</a></li>
          <li><a href=${config.slaveryUrl} rel="noopener nofollow">MODERN SLAVERY STATEMENT</a></li>
          <li><a href=${config.genderGap} rel="noopener nofollow">GENDER PAY GAP REPORT</a></li>
        </ul>
      </nav>
    </div>
  </footer>`;
}


document.body.appendChild(render());